import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import { Grid, Header, Container } from 'semantic-ui-react'
import { projects } from './App'
// import { h1 } from "./layout.css";

const ProjectHeader = ({ location }) => {
  const isCurrent = path => {
    const currentProject = location.pathname.replace(PUBLIC_PATH, '')
    const parent = window.location.pathname.replace(PUBLIC_PATH, '').split('/')[0]
    return currentProject === path || parent === path ? true : false
  }

  const [open, setOpen] = useState(false)

  const optionClass = path => {
    return isCurrent(path) ? 'current' : ''
  }

  const menus = projects
    .filter(prj => !prj.subProject)
    .map(prj => {
      const subMenus = prj.subCodes
        ? prj.subCodes.map(cd => projects.find(pj => pj.code === cd))
        : []
      return {
        ...prj,
        subMenus
      }
    })

  return (
    <Container className={`header-container ${open ? 'open' : ''}`}>
      <Grid>
        <Grid.Column computer={5}>
          <Link to={PUBLIC_PATH}>
            <Header as="h1" className={'h1'}>
              MASATAKA KOUGO
            </Header>
          </Link>
        </Grid.Column>
        <Grid.Column computer={11}>
          <ul className="projects">
            <li>
              <div className={`btn-trigger ${open ? 'open' : ''}`} onClick={() => setOpen(!open)}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </li>
            {menus.map((p, i) => {
              if (p.name === 'ALL') return null
              if (p.name === 'PERSONAL WORKS') {
                return (
                  <li className={`project ${optionClass(p.path)}`} key={p.path}>
                    <Link to={PUBLIC_PATH + p.path}>{p.name}</Link>
                    {isCurrent(p.path) && (
                      <ul>
                        {p.subMenus.map(s => (
                          <li className={`project ${optionClass(s.path)}`} key={s.path}>
                            <Link to={PUBLIC_PATH + s.path}>{s.subTitle}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )
              }
              return (
                <li className={`project ${optionClass(p.path)}`} key={i}>
                  <Link to={PUBLIC_PATH + p.path}>{p.name}</Link>
                </li>
              )
            })}
            <li className={`project ${optionClass('contact')}`}>
              {<Link to={PUBLIC_PATH + 'contact'}>contact</Link>}
            </li>
            <li className={`project ${optionClass('profile')}`}>
              <Link to={PUBLIC_PATH + 'profile'}>profile</Link>
            </li>
          </ul>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

ProjectHeader.propTypes = {
  location: PropTypes.object
}

export default ProjectHeader
