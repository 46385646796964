import React from 'react'
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'
import importedComponent from 'react-imported-component'

import Main from './Main'
import Profile from './Profile'
import Contact from './Contact'
import Loading from './Loading'
import ScrollToTop from './ScrollToTop'

const AsyncNoMatch = importedComponent(() => import(/* webpackChunkName:'NoMatch' */ './NoMatch'), {
  LoadingComponent: Loading
})

export const projects = [
  {
    name: 'ALL',
    path: '/'
  },
  {
    code: 1,
    name: 'HUMAN',
    path: 'human'
  },
  {
    code: 2,
    name: 'LANDSCAPE',
    path: 'landscape'
  },
  {
    code: 3,
    name: 'STILLLIFE',
    path: 'stilllife'
  },
  {
    code: 4,
    name: 'PERSONAL WORKS',
    path: 'personal_works',
    subCodes: [5, 6]
  },
  {
    code: 5,
    subTitle: 'NIAGE',
    name: 'PERSONAL WORKS/NIAGE',
    path: 'personal_works/niage',
    subProject: true
  },
  {
    code: 6,
    subTitle: 'BRIDGE',
    name: 'PERSONAL WORKS/BRIDGE',
    path: 'personal_works/bridge',
    subProject: true
  }
]

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <div>
          <Switch>
            {projects.map((p, i) => {
              return (
                <Route
                  exact
                  key={i}
                  path={PUBLIC_PATH + p.path}
                  render={props => <Main project={p} {...props} />}
                />
              )
            })}
            <Route exact path={PUBLIC_PATH + 'contact'} render={props => <Contact {...props} />} />
            <Route exact path={PUBLIC_PATH + 'profile'} render={props => <Profile {...props} />} />
            <Route component={AsyncNoMatch} />
          </Switch>
        </div>
      </ScrollToTop>
    </Router>
  )
}

export default App
