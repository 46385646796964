import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { PropTypes } from 'prop-types'

const propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

ScrollToTop.propTypes = propTypes
export default withRouter(ScrollToTop)
