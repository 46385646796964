import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import Layout from './Layout'

import { Header, Grid, Icon } from 'semantic-ui-react'

const nl2br = text => {
  const regexBr = /(\n)/g
  return text.split(regexBr).map(line => (line.match(regexBr) ? <br /> : line))
}

const nl2p = text => {
  const regexP = /(\n\n)/g
  return text.split(regexP).map(line => (line.match(regexP) ? '' : <p>{nl2br(line)}</p>))
}

const Profile = ({ location }) => {
  const [fetched, setFetched] = useState(false)
  const [profile, setProfile] = useState({})

  useEffect(() => {
    fetch(`${API_PATH}/profile`)
      .then(res => res.json())
      .then(res => {
        setProfile(res)
        setFetched(true)
      })
  }, [])

  if (!fetched) return null

  return (
    <Layout location={location}>
      <div className="profile-container">
        <Grid textAlign="center">
          <Grid.Column computer={8} mobile={14}>
            <div>
              <a
                href="https://www.instagram.com/masataka_kougo/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="instagram" size="big" color="black" />
              </a>
              <Header as="h2">向後　真孝</Header>
              {nl2p(profile.bio)}
              <Header as="h3">MAGAZINE</Header>
              {nl2p(profile.magazine)}
              {profile.mv && (
                <>
                  <Header as="h3">MV</Header>
                  {nl2p(profile.mv)}
                </>
              )}
              {profile.movie && (
                <>
                  <Header as="h3">MOVIE</Header>
                  {nl2p(profile.movie)}
                </>
              )}
              {profile.client && (
                <>
                  <Header as="h3">CV</Header>
                  {nl2p(profile.client)}
                </>
              )}
              <div className="promotion mail">
                <p>
                  Mail: <a href="mailto:kougo0414527@gmail.com">kougo0414527@gmail.com</a>
                </p>
              </div>
              <div className="copyright">
                <p>All photos are copyright © 2020 Masataka Kougo.</p>
                <p>All rights reserved.</p>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </Layout>
  )
}

Profile.propTypes = {
  location: PropTypes.object
}

export default Profile
