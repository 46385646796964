import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import SwiperCore, { Navigation, Keyboard } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// import { Container } from 'semantic-ui-react'

import { LazyLoadImage } from 'react-lazy-load-image-component'

// Import Swiper styles
import 'swiper/swiper.scss'

SwiperCore.use([Navigation, Keyboard])

const ImageModal = props => {
  const { setOpen, open, images, index } = props

  const [caption, setCaption] = useState('')
  const [transitioning, setTransitioning] = useState(false)

  return (
    <>
      {open && (
        <div className="image-modal">
          <div className="close button close-menu" onClick={() => setOpen(false)}></div>
          <Swiper
            onSlideChange={() => console.log('slide change')}
            onSwiper={swiper => console.log(swiper)}
            onSlideChangeTransitionStart={() => {
              setTransitioning(true)
              setCaption('')
            }}
            onSlideChangeTransitionEnd={slide => {
              const image = images[slide.realIndex]
              setTransitioning(false)
              setCaption(image.caption)
            }}
            direction={'vertical'}
            initialSlide={index}
            loop={true}
            navigation
            mousewheel
            keyboard={true}
            lazy
          >
            {images.map((image, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="slide-image">
                    <LazyLoadImage src={image.url} />
                    <div className={`caption ${transitioning && 'transitioning'}`}>
                      {caption &&
                        caption.split('\n').map((i, key) => {
                          return <p key={key}>{i}</p>
                        })}
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      )}
    </>
  )
}

ImageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired
}

export default ImageModal
