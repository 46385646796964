import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import Layout from './Layout'
import { Grid, Form, Button, Dimmer, Loader } from 'semantic-ui-react'

const Contact = ({ location }) => {
  const [contact, setContact] = useState({
    name: '',
    email: '',
    phone: '',
    subject: 'ホームページからお問い合わせ',
    honeypot: '', // if any value received in this field, form submission will be ignored.
    message: '',
    replyTo: '@', // this will set replyTo of email to email address entered in the form
    accessKey: 'c29ca03c-4400-4822-9d80-519c1f3312e3' // get your access key from https://www.staticforms.xyz
  })

  const [response, setResponse] = useState({
    type: '',
    message: () => ''
  })

  const [isLoading, setIsLoading] = useState(false)

  const handleChange = e => setContact({ ...contact, [e.target.name]: e.target.value })

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const res = await fetch('https://api.staticforms.xyz/submit', {
        method: 'POST',
        body: JSON.stringify(contact),
        headers: { 'Content-Type': 'application/json' }
      })

      setIsLoading(false)
      const json = await res.json()

      if (json.success) {
        setResponse({
          type: 'success',
          message: () => (
            <p>
              送信完了しました。
              <br />
              お問い合わせいただきありがとうございます。
            </p>
          )
        })
      } else {
        setResponse({
          type: 'error',
          message: json.message
        })
      }
    } catch (e) {
      setResponse({
        type: 'error',
        message: () => <p>入力内容をお確かめください。</p>
      })
    }
  }

  return (
    <Layout location={location}>
      <div className="form-container">
        <Grid textAlign="center">
          <Grid.Column computer={5} mobile={14}>
            <Dimmer inverted active={isLoading}>
              <Loader />
            </Dimmer>
            {response.message()}
            {/*成功したら非表示にする*/}
            {response.type !== 'success' && (
              <Form
                action="https://api.staticforms.xyz/submit"
                method="post"
                onSubmit={handleSubmit}
              >
                <Form.Field>
                  <Form.Input
                    fluid
                    label="お名前"
                    className="input"
                    type="text"
                    placeholder="氏名、会社名など"
                    name="name"
                    onChange={handleChange}
                    required
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    label="Eメールアドレス"
                    className="input"
                    type="email"
                    placeholder="your_address@example.com"
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    label="電話番号"
                    className="input"
                    type="tel"
                    placeholder="0901231234"
                    name="phone"
                    onChange={handleChange}
                    required
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    type="text"
                    name="honeypot"
                    style={{ display: 'none' }}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.TextArea
                    label="お問い合わせ内容"
                    className="textarea"
                    placeholder="お問い合わせ内容をご入力ください"
                    name="message"
                    onChange={handleChange}
                    required
                  />
                </Form.Field>
                <Button className="button is-primary" type="submit">
                  送信
                </Button>
              </Form>
            )}
          </Grid.Column>
        </Grid>
      </div>
    </Layout>
  )
}

Contact.propTypes = {
  location: PropTypes.object
}

export default Contact
