import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Image } from 'semantic-ui-react'
import Layout from './Layout'
import ImageModal from './ImageModal'

const Main = props => {
  const {
    project: { code, subCodes, name },
    location
  } = props

  const [thums, setThums] = useState([])
  const [images, setImages] = useState([])

  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const setContents = respons => {
    const sorted = respons.sort((a, b) => {
      if (a.order !== null) return a.order - b.order
      return a.id - b.id
    })

    setImages(sorted.map(cnt => ({ url: cnt.image.url, caption: cnt.comment })))
    setThums(
      sorted.map(cnt =>
        cnt.image.formats.small ? cnt.image.formats.small.url : cnt.image.formats.thumbnail.url
      )
    )
  }

  useEffect(() => {
    if (name === 'ALL') {
      fetch(`${API_PATH}/posts?category.hidden_in_index_null=true&_limit=-1`)
        .then(res => res.json())
        .then(reses => setContents(reses))
    } else {
      const codes = subCodes || [code]
      Promise.all(
        codes.map(cd =>
          fetch(`${API_PATH}/posts/?category=${cd}&_limit=-1`).then(res => res.json())
        )
      ).then(reses => {
        const flatten = reses.reduce((acc, val) => acc.concat(val), [])
        setContents(flatten)
      })
    }
  }, [])

  const onClickThum = i => {
    setOpen(true)
    setIndex(i)
  }

  return (
    <Layout location={location}>
      <div className="thumbnails">
        <ul>
          {thums.map((path, i) => (
            <li className="thumbnail" key={i}>
              <Image onClick={() => onClickThum(i)} src={path} />
            </li>
          ))}
        </ul>
      </div>
      <ImageModal open={open} setOpen={setOpen} index={index} images={images} />
    </Layout>
  )
}

Main.propTypes = {
  project: PropTypes.object,
  location: PropTypes.object
}

export default Main
