import React from 'react'
import { PropTypes } from 'prop-types'
import { Container } from 'semantic-ui-react'

import Header from './Header'

// import { pullRight } from "./layout.css";

const Layout = ({ children, location }) => {
  return (
    <Container>
      <Header location={location} />
      {children}
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
}

export default Layout
