import { hot } from 'react-hot-loader/root'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'

// import './index.css'
import './sass/style.scss'

const Component = hot(App)
ReactDOM.render(<Component />, document.getElementById('root'))
